/* ==========================================================================
   Header
   ========================================================================== */

.header {
  position: relative;
  z-index: 999;

  transition: $transition-base;

  @extend .bg-white;
  @extend .pt-2;

  @include media-breakpoint-up(xl) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

/* Header-socket
   ========================================================================== */
  .header-socket {

    .container-navbar {
      @extend .px-2;
      @extend .px-md-5;
      
      .column.navbar {
        @extend .navbar-expand;
        @extend .justify-content-end;
        @extend .pb-0;
        @extend .pt-0;
        @extend .pt-md-2;

        .collapse {
          flex-grow: inherit!important;
          @extend .mr-2;
          @extend .d-none;
          @extend .d-md-block;
        }

        .navbar-nav {

          
          .nav-item {
            
            .nav-link {
              @extend .tt-menu-sub;
              &:hover {
                @extend .tt-menu-sub--hover;
              }
            }
            &.active {
              .nav-link {
                @extend .tt-menu-sub--active;
              }
            }
  
          }
  
        }

        .socials {
          @extend .flex-wrap;

        }
        .language {
          li {
            a {
              @extend .align-items-center;
              @extend .px-1;
              line-height: 0;
              img {
                max-width: 20px;
              }
            }
          }
        }
      }
    }
  }

/* Header-main
   ========================================================================== */
  .header-main {

    .container-navbar {
      @extend .px-2;
      @extend .px-md-5;
    }

    .column.navbar {
      @extend .navbar-expand-xl;
      @extend .navbar-light;
      @extend .pb-3;
      @extend .py-xl-0;
      
      
      .navbar-nav {

        @extend .mt-3;
        @extend .mt-xl-0;

        .nav-item {
          @extend .px-0;
          @extend .px-xl-3;

          .nav-link {
            @extend .tt-menu-main;
            @extend .px-0;
            @extend .pb-2;
            @extend .pb-xl-4;
            &:hover {
              @extend .tt-menu-main--hover;
            }
          }

          .dropdown-menu {
            @extend .border-0;
            @extend .px-2;
            border-radius: 0;
            @include media-breakpoint-up(xl) {
              box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
            }

            .nav-link {
              @extend .py-1;
              font-size: 0.875rem;
            }
          }

          &.active {
            > .nav-link {
              @extend .tt-menu-main--active;
              @include media-breakpoint-up(xl) {
                border-bottom: 3px solid theme-color("secondary");
              }
            }
          }
          &.last {
            @extend .pr-0;
          }
          

        }

      }

    }

    .logo {
      max-width: 203px;
      margin-top: -50px;
      transition: all 0.5s ease;

      @include media-breakpoint-down(md) {
        max-width: 150px;
        margin-top: -30px;
      }

      a {
        @extend .d-block;

        img {
          @extend .w-100;
        }
      }
    }

    .navbar-toggler {

      background: $primary;
      line-height: 0.6rem;
      padding: 5px;
      @extend .border-0;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);

      .navbar-toggler-icon {
        width: auto;
        height: auto;
        background-image: none!important;
        color: $white;
        font-size: 1rem;
      }
      .navbar-toggler-label {
        @extend .tt-menu-toggler;
        @extend .px-2;
      }
    }
    
    .navbar-collapse {
      .navbar-nav { 
        @extend .ml-auto;
      }
    }
  }

/* Header - Sticky
   ========================================================================== */
  &.sticky {
    @include media-breakpoint-up(xl) {
      position: fixed;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
      z-index: 1000;
    }
    @extend .py-0;

    .header-socket {
      .container-navbar {
        .column.navbar {
          // @extend .py-0;
        }
      }
    }

    .header-main {
      .logo {
        @include media-breakpoint-up(xl) {
          max-width: 180px;
        }
      }
      .column.navbar {
        // @extend .py-0;
        .navbar-nav {
          .nav-item {
            .nav-link {
              @extend .pb-2;
            }
          }
        }
      }
    }
  }
}