.owl-carousel {
  .item {
    @include carousel-height();
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .owl-holder {
      @include carousel-height();
      @extend .d-flex;
      @extend .align-items-center;

      .owl-caption {
        @extend .py-5;
        color: $white;
      }
    }
  }

  .owl-nav {
    position: absolute;
    right: 0;
    bottom: 30px;
    left: 0;

    @extend .d-flex;
    @extend .justify-content-between;
    height: 0;

    @include media-breakpoint-up(md) {
      bottom: 50%;
      margin-bottom: 15px;
    }

    button.owl-prev,
    button.owl-next {
      display: block;
      width: 30px;
      outline: 0;

      line-height: 30px;
      text-align: center;
      color: $white;

      transition: $transition-base;
    }
    button.owl-prev {
      margin-left: 10px;

      &:hover {
        margin-left: 5px;
      }
    }
    button.owl-next {
      margin-right: 10px;

      &:hover {
        margin-right: 5px;
      }
    }
  }

  .owl-dots {
    position: absolute;
    right: 0;
    bottom: 30px;
    left: 0;

    height: 0;

    @extend .d-flex;
    @extend .justify-content-center;

    .owl-dot {
      @extend .d-block;
      outline: 0;
      
      span {
        @extend .d-block;
        width: 15px;
        height: 15px;
        margin: 7px 5px;

        background-color: transparent;
        border: 2px solid $white;
        border-radius: 30px;

        transition: $transition-base;
      }

      &:hover,
      &.active {
        span {
          background-color: $white;
          border-color: $white;
        }
      }
    }
  }
}