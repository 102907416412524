.list {
  margin: 0;
  padding: 0;
  list-style: none;

  .list-item {
    > .title {
      font-size: $h4-font-size;
      color: $bg-color-primary;
    }

    .sub-list {
      .title {
       font-weight: bold;
      }
      
      .sub-list-item {
        display: flex;
        flex-wrap: wrap;
        @extend .mb-2;

        .icon {
          flex: 0 0 auto;

          &:not(:empty) {
            @extend .mr-2;
          }
        }

        .title {
          flex: 1 1 auto;
        }
        
        .short-description {
          flex: 0 0 100%;
        }
      }
    }
  }
}