@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$blue:    #6597FD;
$indigo:  #111E72;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #bbd331;
$teal:    #20c997;
$cyan:    #17a2b8;

$dark-gray:		#1a2226;
$light-gray:	#b8bec3;

$primary:       $blue;
$secondary:     $indigo;
$tertiary:			$gray-500;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

$theme-colors: (
	"primary":    $primary,
	"secondary":  $secondary,
	"tertiary":		$tertiary,
	"success":    $success,
	"info":       $info,
	"warning":    $warning,
	"danger":     $danger,
	"light":      $light,
	"dark":       $dark
);


// Body
// 
// Settings for the `<body>` element.

$body-bg:                   	$white;
$body-color:                	$gray-900;


// Links
//
// Style anchor elements.

$link-color:              		theme-color("primary");
$link-decoration:         		none;
$link-hover-color:        		darken($link-color, 15%);
$link-hover-decoration:				underline;


// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   	1rem;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           30px;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:      'Quicksand', sans-serif;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;

$font-size-base:							0.875rem; // Assumes the browser default, typically `16px`

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          500;
$font-weight-medium:          600;
$font-weight-bold:            700;
$font-weight-bolder:          bolder;

$font-weight-base:            $font-weight-medium;
$line-height-base:            1.75;

$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 1.75;
$h3-font-size:                $font-size-base * 1.5;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

// Backgrounds
//
// colors

$bg-color-primary:            $primary;
$bg-color-secondary:          $secondary;


// 3W MEDIA - CUSTOM STYLING

// theme styling
$theme-color-primary: 				$primary;
$theme-color-primary-dark: 		darken($theme-color-primary, 10%);

$theme-color-secondary: 			$secondary;
$theme-color-secondary-dark: 	darken($theme-color-secondary, 10%);

$theme-color-tertiary: 				$light-gray;
$theme-color-tertiary-dark: 	darken($theme-color-tertiary, 10%);

// Eyecatcher
$eyecatcher-height-big: 			70vh;
$eyecatcher-height-sm-big: 		350px;
$eyecatcher-height-small: 		50vh;
$eyecatcher-height-sm-small: 	30vh;

// TOMMY
$zeb-color-primary: 			$theme-color-primary;
$zeb-color-secondary: 		$theme-color-secondary;
$zeb-color-tertiary: 			$theme-color-primary-dark;

.bg-color-primary 				{ background-color: $bg-color-primary!important; }
.bg-color-secondary 			{ background-color: $bg-color-secondary!important; }

// SECTION PADDING + MARGINS
$section-pt: 50px;
$section-pb: 50px;
$section-pt-md: 90px;
$section-pb-md: 90px;

