.btn {
  background: $bg-color-primary;
  border-color: $bg-color-primary;
  color: $white;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  &:hover {
    background: $bg-color-secondary;
    border-color: $bg-color-secondary;
    color: $white;
  }
}

.btn-dark {
  background: $bg-color-secondary;
  border-color: $bg-color-secondary;
  &:hover {
    background: lighten($theme-color-secondary, 10%);
    border-color: lighten($theme-color-secondary, 10%);
  }
}

.btn-wide {
  min-width: 240px;
}

.btn-link {
  &--primary {
    color: $secondary;
    @extend .p-0;

    &:hover {
      color: $primary;
    }
  }

  &--white {
    color: $white;

    &:hover {
      color: $primary;
    }
  }
}

.btn-back {
  @extend .mb-4;
  @extend .add-arrow--before;
  @extend .add-arrow--before-r;
}