/* ==========================================================================
   Footer
   ========================================================================== */

.footer {

	/* Footer-bar
   ========================================================================== */
	.footer-bar {
		@extend .py-5;
		@extend .px-3;
		@extend .px-sm-0;

    @extend .bg-primary;

    .container-holder {
      .column {
        @extend .my-0;
      }
    }

    .footer_text {
      
      > * {
        color: $white;
      }
      h1, h2, h3, h4 {
        @extend .my-0;
      }
      h2 {
        font-size: $h1-font-size;
      }
      a {
        @extend .btn;
        @extend .btn-dark;
        @extend .btn-wide;
        @extend .mt-3;
      }

      @include media-breakpoint-down(md) {
        text-align: center;
      }
      
    }
    .footer_logolink {

      @extend .mt-3;
      @extend .mt-md-0;
      .list {
        @extend .justify-content-center;
        @extend .justify-content-md-end;
        @extend .align-items-center;
      }
      .link {
        color: $white;
        font-size: 60px;
        @extend .mx-2;
        transition: $transition-base;
        @include media-breakpoint-down(md) {
          font-size: 40px;
        }
        &:hover {
          color: $secondary;
        }
      }
    }
    


	}

	/* Footer-top
   ========================================================================== */
	.footer-top {
    @extend .pt-5;
    @extend .pb-0;
		@extend .px-3;
    @extend .px-sm-0;

    background: transparent linear-gradient(360deg, #FFFFFF 0%, #E5E5E5 100%) 0% 0% no-repeat padding-box;
    
    .footer_text {
      @extend .text-center;
      font-size: $h5-font-size;
      
      i.far {
        font-size: $h1-font-size;
        color: $primary;
        @extend .mb-3;
      }

      &::after {
        content: '';
        height: 30px;
        background: transparent url('/images/wave-blue.png') 50% 0 no-repeat;
        display: block;
        margin: 60px auto 0;
        width: 100%;
      }
    }

	}

	/* Footer-middle
   ========================================================================== */
	.footer-middle {
		@extend .py-5;
		@extend .px-3;
    @extend .px-sm-0;
    @extend .d-none;
    @extend .d-md-block;

    h5 {
      color: $secondary;
      font-weight: $font-weight-base;
      @extend .mb-3;
    }
    a {
      color: $black;
      text-decoration: underline;
    }

	}

	/* Footer-bottom
   ========================================================================== */
	.footer-bottom {
    @extend .py-3;
    font-size: 0.8rem;

		.container-navbar {
      @extend .px-0;
      .column {
        @extend .py-0;
        @extend .pb-3;
        .footer_link {
          .list {
            @extend .justify-content-center;
            @extend .flex-column;
            @extend .flex-md-row;
            @extend .align-items-center;
          }
        }
      }
		}

		.column.navbar {
			@extend .p-0;
		}
	}
}
