.eyecatcher {
	position: relative;
	min-height: $eyecatcher-height-small;
	background-color: #EEEEEE;

	@include media-breakpoint-down(md) {
		min-height: $eyecatcher-height-sm-small;
	}
  
	.container-default {
		@extend .px-0;
	}
	
	&::before {
		position: absolute;
		top: 50%;
		left: 50%;

		display: block;
		margin-top: -25px;
		margin-left: -25px;

		font-family: "Font Awesome 5 Pro";
		font-size: 50px;
		line-height: 50px;
		color: #BBBBBB;

		content: "\f1ce";

		animation: fa-spin 2s infinite linear;
	}
  
	&.large {
		min-height: $eyecatcher-height-sm-big;
  }
}