@mixin carousel-height {
	min-height: $eyecatcher-height-small;
	
	@include media-breakpoint-down(md) {
		min-height: $eyecatcher-height-sm-small;
	}

  .large & {
		min-height: $eyecatcher-height-big;
		@include media-breakpoint-down(md) {
			min-height: $eyecatcher-height-sm-big;
		}
  }
}

// SECTION PADDING + MARGIN
.section-ypadding {

	padding-top: $section-pt;
	padding-bottom: $section-pb;

	@include media-breakpoint-up(md) {

		padding-top: $section-pt-md;
		padding-bottom: $section-pb-md;

	}

}