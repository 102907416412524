.default {
	&.service_overview {
		.section_one {
			@extend .px-3;
			@extend .py-4;

			@extend .px-sm-5;
			@extend .py-sm-5;
		}
		.item {
			@extend .mb-5;
		}

		h1 {
			@extend .tt-content-h-special;
		}
	}

	&.service_category {
		.section_one {
			@extend .px-3;
			@extend .py-4;

			@extend .px-sm-5;
			@extend .py-sm-5;
		}

		.service_category_detail {
			@include make-row();
			@extend .pb-5;

			.info {
				@include make-col-ready();
				@include make-col(12);

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				@extend .mb-4;
				@extend .pr-lg-5;

				h1 {
					@extend .tt-content-h2;
				}
			}

			.content {
				@include make-col-ready();
				@include make-col(12);

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				@extend .mb-4;
				@extend .pl-lg-5;

				@extend .d-flex;
				@extend .justify-content-end;
				@extend .flex-column;
				@extend .align-items-end;

				.btn-back {
					@extend .btn;
				}
			}
		}
	}

	&.service_detail {
		.section_one {
			@extend .px-3;
			@extend .py-4;

			@extend .px-sm-5;
			@extend .py-sm-5;
		}
			
		.service_detail {
			@include make-row();

			.info {
				@include make-col-ready();
				@include make-col(12);

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				@extend .mb-4;
				@extend .pr-lg-5;

				h1 {
					@extend .tt-content-h2;
				}
			}

			.content {
				@include make-col-ready();
				@include make-col(12);

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				@extend .mb-4;
				@extend .pl-lg-5;

				@extend .d-flex;
				@extend .justify-content-end;
				@extend .flex-column;
				@extend .align-items-end;

				.btn-back {
					@extend .btn;
				}
			}
		}
	}
}