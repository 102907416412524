@import url('https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css');

/* custom - op basis van TommyBookingSupport */
#TommyBookingSupport { font-size: 14px;}
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav>.tbs-active>a, 
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav>.tbs-active>a:focus, 
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav>.tbs-active>a:hover { color: $white; background-color: $zeb-color-primary;}
#TommyBookingSupport .tbs-btn-info { color: $white;  background-color: $zeb-color-primary; border-color: $zeb-color-primary;}
#TommyBookingSupport .tbs-btn-info:hover { background-color: $zeb-color-secondary; border-color: $zeb-color-secondary;}
#TommyBookingSupport .tbs-btn-success { color: $white; background-color: $zeb-color-secondary; border-color: $zeb-color-secondary;}
#TommyBookingSupport .tbs-btn-success:hover { color: $white; background-color: $theme-color-primary-dark; border-color: $theme-color-primary-dark;}
#TommyBookingSupport .tbs-btn-info, #TommyBookingSupport .tbs-btn-success { transition: all 0.5s ease;}

body #TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected { background-color: $zeb-color-primary;}

#TommyBookingSupport .tbs-row.tbs_persoonscategorien, #TommyBookingSupport .tbs-row.tbs_accommodaties, #TommyBookingSupport .tbs-row.tbs_kalenders, #TommyBookingSupport .tbs-container-fluid.tbs_kassabon, #TommyBookingSupport .tbs-row.tbs_artikelen, #TommyBookingSupport .tbs-row.tbs_persoon, #TommyBookingSupport .tbs-row .tbs-page-overzicht > .tbs-container-fluid > .tbs-row:nth-child(2) {
    background-color: rgb(245, 245, 245)!important
}

/* flex order */
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types { order: 0;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories { order: 1;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date { order: 2;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-confirm-update { order: 3;}

/* custom - op basis van TommyBookingSupport MINI Widget */
.mini-sab .tommy-zeb-wrapper {
    float: none;
    width: auto;
}
.mini-sab .tommy-zeb-wrapper * { 
    font-family: $font-family-sans-serif!important;
    font-size: 14px;
}
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left {
    margin-top: -10px;
    float: none;
    background: none;
    color: $white;
    padding: 0 !important;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini h2.ng-binding { display: none; }
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left { justify-content: start;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
    margin-right: 15px;
    padding-right: 0;
    border-right: none;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories .ng-scope { display: none;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories .ng-scope:nth-child(1) { display: inline;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-label { display: none;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories .ng-scope:nth-child(1)::before {
    display: block;
    content: 'Personen';
    color: $white;
    line-height: 30px;
    font-weight: 500;
}

/* Accommodatietype */
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types { display: block !important;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types .ng-isolate-scope { 
    padding: 5px 0px;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types .tommy-zeb-accommodation-type-choice::before {
    display: block;
    content: 'Accommodatietype';
    color: $white;
    line-height: 30px;
    font-weight: 500;
}
.mini-sab [data-language="de"] .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types .tommy-zeb-accommodation-type-choice::before {
    content: 'Art der Unterkunft';
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date-begin-label label { display: none;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date-begin-label::before {
    display: block;
    content: 'Aankomst';
    color: $white;
    line-height: 30px;
    font-weight: 500;
}
.mini-sab [data-language="de"] .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date-begin-label::before {
    content: 'Ankunft';
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date-end-label label { display: none;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date-end-label::before {
    display: block;
    content: 'Vertrek';
    color: $white;
    line-height: 30px;
    font-weight: 500;
}
.mini-sab [data-language="de"] .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date-end-label::before {
    content: 'Abfahrt';
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category select { width: 70px;}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left input { line-height: 20px;}
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left input,
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select { padding: 5px; margin: 0; width: 160px;}
.mini-sab .tommy-zeb-period .tommy-zeb-date-begin { margin-right: 5px;}
.mini-sab .tommy-zeb-period .tommy-zeb-date-begin-label,
.mini-sab .tommy-zeb-period .tommy-zeb-date-end-label { float: none !important; display: block;}
.mini-sab .tommy-zeb-period .tommy-zeb-date-begin-label label, .mini-sab .tommy-zeb-period .tommy-zeb-date-end-label label { margin-bottom: 0 !important; line-height: 30px; display: block;}
.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date-begin,
.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date-end {
    padding: 5px 0px;
    float: left;
    margin-bottom: 0;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories, .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date {
    border-right: none;
    padding-right: 0;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select, .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-period input {
    background-color: $white;
    border:none;
    padding: 7px;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-period input { 
    width: 100px; 
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-begin input {
    @include media-breakpoint-up(md) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-end input {
    @include media-breakpoint-up(md) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}


.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn {
    float: none;
    margin: 35px 0 0 0 !important;
    border: none;
    border-radius: 4px;
    background-color: $zeb-color-primary;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    color: $white;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    cursor: pointer;
}
.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn:hover {
    background-color: $theme-color-primary-dark;
    color: $white;
}

@media (min-width: 980px) and (max-width: 1199px) {
    
    .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-label,
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-begin-label,
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-end-label { width: auto;}

}

@media (max-width: 767px) {

    .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left { display: block;}
    .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories,
    .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
        border-bottom: none;
        padding: 0;
        margin: 0;
    }
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date,
    .mini-sab .tommy-zeb-wrapper-mini .ng-isolate-scope { float: none; clear: both; box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);}
    .mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn { margin: 30px 0 0 !important; }
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-begin-label, 
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-end-label { width: auto;}
    .mini-sab .tommy-zeb-wrapper-mini tommy-confirm-update { display: block;}

    .mini-sab .tommy-zeb-wrapper .tommy-zeb-left select,
    .mini-sab .tommy-zeb-wrapper .tommy-zeb-left input,
     .mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn {
        display: block !important;
        width: 100% !important;
    }
    .mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn {
        display: inline-block!important;
    }

}

/* custom - op basis van TommyBookingSupport */
.accommodation_search_book .main .tommy-zeb-wrapper * { font-family: $font-family-sans-serif!important; }
.accommodation_search_book .main .tommy-zeb-wrapper .tommy-zeb-left { background: $zeb-color-primary; border-radius: 0; }
.accommodation_search_book .main .tommy-zeb-wrapper .tommy-zeb-result.ng-scope { @extend .d-flex; border-radius: 0;  }
.accommodation_search_book .main .tommy-zeb-wrapper .tommy-zeb-left h1 { display: none!important;}
.accommodation_search_book .main .tommy-zeb-wrapper * h2 { 
    color: #000;
    font-weight: 400;
}
.accommodation_search_book .main .tommy-zeb-wrapper * h2::after {
    border-color: $white;
}
.accommodation_search_book .main .tommy-zeb-wrapper .tommy-zeb-left h2 { color: $white; font-size: 1.25rem;}
.accommodation_search_book .main .tommy-zeb-wrapper .tommy-zeb-person-categories h2 { margin-top: 0; }
.accommodation_search_book .main .tommy-zeb-wrapper .tommy-zeb-btn {
    min-width: 160px;
    padding: 7px 12px;
    background-color: $zeb-color-secondary;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    color: $white;
	font-size: 13px;
	font-weight: 700;
	line-height: 20px;
    text-align: center;
}
.accommodation_search_book .main .tommy-zeb-wrapper .tommy-zeb-btn:hover { background-color: $theme-color-secondary-dark;}

.accommodation_search_book .main .tommy-zeb-wrapper .tommy-zeb-result-label.ng-binding { color: #000; font-weight: 700;  }
.accommodation_search_book .main .tommy-zeb-wrapper .tommy-zeb-result-price.ng-binding { color: #000; font-weight: 700;}
.accommodation_search_book .main .tommy-zeb-wrapper .tommy-zeb-result-label.ng-binding::after {
    content: '';
    display: block;
    border-bottom: 3px solid $zeb-color-primary;
    width: 120px;
    margin: 10px 0 15px;
}

@media (min-width: 1200px) {
    /* custom - op basis van TommyBookingSupport - Zoek en Boek */
    .accommodation_search_book .main .tommy-zeb-wrapper .tommy-zeb-result-image img { max-width: 250px;}
    .accommodation_search_book .main .tommy-zeb-wrapper .tommy-zeb-result-details { width: 545px;}
}
@media (min-width: 980px) and (max-width: 1199px) {
    /* custom - op basis van TommyBookingSupport - Zoek en Boek */
    .accommodation_search_book .main .tommy-zeb-wrapper .tommy-zeb-result-image img { max-width: 180px;}
}