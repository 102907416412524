.page_block {
  &.accommodation_arrangement {
    &.grid {
      .item {
        @include media-breakpoint-up(lg) {
          @include make-col(6);
        }

				@include media-breakpoint-up(xl) {
					@include make-col(6);
				}
			}
    }
  }

  &.blog_overview {
    &.grid {
      .item {
        @include media-breakpoint-up(sm) {
          @include make-col(12);
        }
  
        @include media-breakpoint-up(md) {
          @include make-col(12);
        }

        @include media-breakpoint-up(lg) {
          @include make-col(6);
        }

				@include media-breakpoint-up(xl) {
					@include make-col(6);
				}
			}
    }
  } 

}