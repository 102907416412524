.mini-sab {

	@extend .p-4;
	@extend .bg-secondary;
	position: relative;
	

	.home & {
		position: absolute;
    margin: -450px 0 0 100px;
    z-index: 10;

    @include media-breakpoint-down(md) {
			margin: 0;
			position: relative;
		}
		
		// check collection blocks
  }

	@include media-breakpoint-down(md) {
    padding: 40px!important;
	}

	.container-default {
		max-width: 650px;
		@include media-breakpoint-down(md) {
			max-width: 100%;
		}

		.container-holder {

      h1 {
        color: $white;
        font-size: $h2-font-size;
        font-weight: 600;
        @extend .mb-5;
        @extend .d-none;
        max-width: 60%;

        .home & {
					@extend .d-block;
				}

        @include media-breakpoint-down(md) {
					margin-bottom: 30px !important;
					max-width: 100%;
        }
      }

		}
	}

	&::before {
		content: '';
		position: absolute;
		background: transparent url('/images/check.png') 0 0 no-repeat;
		background-size: cover;

		bottom: -10px;
		right: 100px;
		width: 100px;
		height: 70px;

		z-index: 999;

		@include media-breakpoint-down(md) {
			bottom: -10px;
			right: 30px;
			width: 100px;
			height: 70px;
		}
		@include media-breakpoint-down(sm) {
			bottom: -25px;
			right: 50%;
			margin-right: -30px;
			width: 60px;
			height: 40px;
		}

		.home & {
			top: 5px;
			right: -30px;
			width: 157px;
			height: 110px;

			@include media-breakpoint-down(md) {
				top: -60px;
				right: 30px;
				width: 127px;
				height: 90px;
			}
		}

		
	}
}
