/* ==========================================================================
   Bootstrap-carousel
   ========================================================================== */

.carousel {
  /* Carousel - Normal */
  .carousel-item {
    @include carousel-height();
    
    .carousel-holder {
      @include carousel-height();

      .carousel-caption {
        @extend .text-left;
      }
    }
  }

  .carousel-control-prev,
  .carousel-control-next,
  .carousel-indicators {
    .large & {
      @extend .d-none;
    }
  }


  /* Carousel - Background */
  .carousel-item-bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .carousel-holder {
      @extend .d-flex;
      @extend .align-items-center;

      .carousel-caption {
        position: relative;
        right: unset;
        bottom: unset;
        left: unset;
        @extend .text-center;
        
        .carousel-title {
          font-size: $h1-font-size;
          font-weight: $font-weight-bold;
        }
        .carousel-subtitle {
          font-size: $h3-font-size;
        }
        .carousel-btn {
          @extend .btn;
          @extend .mt-4;
        }
      }
    }
  }
  .carousel-indicators {
    li {
      background: $white;
      border: none;
      opacity: 1;
      &.active {
        background: $primary;
      }
    }
  }
}