// tommy hack - reset overrule tommy
html.tommy {
    font-size: 1rem;
}

/*  TOMMY RESET/IMPROVEMENTS */
.tommy-matrix                                                               { width: 100%!important; }
.tommy-matrix .the-matrix .vertical-align                                   { display: flex; flex-direction: column; }
.tommy-matrix .matrix-bar                                                   { margin: 0 auto; }
.tommy-matrix .matrix-bar fieldset                                          { padding: 0; margin: 0; border: none; }
.tommy-matrix .matrix-bar label::after                                      { display: none; }
.tommy-matrix .the-matrix.periods .matrix-row .sticky-point                 { margin: 0 1px 0 0; }
.tommy-matrix .popup .close                                                 { cursor: pointer; }
.tommy-matrix .the-matrix .matrix-row .fixed-one,
.tommy-matrix .the-matrix .matrix-beschikbaarheid,
.tommy-matrix .matrix-row.arrangement                                       { height: 80px; }
.tommy-matrix .the-matrix.periods .matrix-row .fixed-one,
.tommy-matrix .the-matrix.periods .matrix-beschikbaarheid,
.tommy-matrix .the-matrix.periods .matrix-row.arrangement                   { height: 60px; }
.tommy-matrix .matrix-row.arrangement                                       { margin-bottom: 2px; }
.tommy-matrix .matrix-row .fixed-one                                        { top: 6px; }
.tommy-matrix .periods .matrix-row .fixed-one                               { top: 3px; }
.tommy-matrix .matrix-row.arrangement .sticky-point                         { margin: 0 1px 0; }
.tommy-matrix .matrix-bar label > select                                    { margin: 5px 0; }
.tommy-matrix .matrix-bar .persons-table td input                           { margin: 0 auto; height: 30px; border: none; }
.tommy-matrix .matrix-bar .input-container                                  { border: none; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); margin: -20px 0 0; z-index: 9999; }
.tommy-matrix .matrix-bar .flex-row > .flex-col,
.tommy-matrix .the-matrix .flex-row > .flex-col                             { margin: 10px 15px 0px 0 !important;}
.tommy-matrix .time-bar                                                     { z-index: 20; }


@media (max-width: 768px) {
	.tommy-matrix .the-matrix.periods .popup                                { width: auto; height: auto; margin: 0; top: 15px!important; left: 15px!important; right: 15px!important; bottom: inherit; }
	.tommy-matrix .matrix-bar .flex-row > .flex-col,
	.tommy-matrix .the-matrix .flex-row > .flex-col                         { margin: 0;  flex: 1 1 100%; }
}
@media (max-width: 576px) {
	.tommy-matrix .the-matrix button, .tommy-matrix .the-matrix .btn        { width: 100%; }
}

/*  BACKGROUND-COLOR CONTAINER/BODY */
.tommy-matrix .the-matrix.periods .matrix-row .fixed-one .fixed-one_text    { border-right: 2px solid #fff; }
.tommy-matrix .matrix-date-row .fixed-one                                   { background: #fff; }
.tommy-matrix .the-matrix::after,
.tommy-matrix .time-bar .time-ff,
.tommy-matrix .time-bar .time-back {
	background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 74%, rgba(255, 255, 255, 1) 100%);
	background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 74%, rgba(255, 255, 255, 1) 100%);
	background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 74%, rgb(255, 255, 255) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
}

/*  CUSTOM CSS */
.tommy-matrix .matrix-bar label                                             { background: none; height: auto; margin-bottom: 15px; }
.tommy-matrix span.label-name                                               { font-size: 16px; margin-bottom: 5px; }
.tommy-matrix .matrix-bar label.active textarea,
.tommy-matrix .matrix-bar label.active input,
.tommy-matrix .matrix-row.arrangement .fixed-one                            { border-color: $zeb-color-primary; }
.tommy-matrix .matrix-bar label.active select                               { border-color: #DADADA; }
.tommy-matrix .matrix-bar label.input-ok::after,
.tommy-matrix .matrix-beschikbaarheid .matrix-prijs,
.tommy-matrix .matrix-beschikbaarheid .matrix-trigger                       { color: #fff;  }
.tommy-matrix .time-bar .time-back,
.tommy-matrix .time-bar .time-ff,
.tommy-matrix .matrix-bar .persons-table td a 															{ color: $zeb-color-secondary; }
.tommy-matrix .matrix-date-row .matrix-time                                 { color: $zeb-color-primary; }
.tommy-matrix span.label-name,
.tommy-matrix .matrix-bar .input-ok select,
.tommy-matrix .matrix-bar .input-ok input																		{ color: #000; }
/* arrows */
.tommy-matrix .matrix-bar label::after                                      { content: '\f0d7'; font-family: 'fontawesome'; position: absolute; display: block; right: 5px; top: 35px; width: 20px;  height: 20px; background-color: #fafbfd; }
/*  cells */
.tommy-matrix .matrix-row .fixed-one .fixed-one_text                        { background: $zeb-color-secondary; color: #fff;}
.tommy-matrix .matrix-beschikbaarheid .matrix-prijs                         { font-size: 14px; font-weight: 700;}
.tommy-matrix .matrix-beschikbaarheid                                       { background: $zeb-color-primary; margin: 0 1px; }
.tommy-matrix .matrix-beschikbaarheid.no-data                               { background: lighten($zeb-color-primary, 20%); }
.tommy-matrix .matrix-beschikbaarheid:not(.no-data):hover                   { background: $zeb-color-secondary;}
.tommy-matrix .matrix-bar .persons-table td a:hover,
.tommy-matrix .time-bar .time-back:hover,
.tommy-matrix .time-bar .time-ff:hover                                      { color: $zeb-color-secondary; }
.tommy-matrix .matrix-beschikbaarheid:hover .matrix-prijs                   { color: $white;}
/*  popup */
.tommy-matrix .the-matrix .popup                                            { border: none; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);  margin: 24px 0 0 55px; z-index: 9999; }
.tommy-matrix .the-matrix .popup .input-container_header                    { background: $zeb-color-secondary;}
.tommy-matrix .the-matrix .popup .show-col-table tr td                      { color: $zeb-color-secondary;}
.tommy-matrix .the-matrix .popup .show-col-table tr.td-total td             { color: $zeb-color-primary;}
.tommy-matrix .the-matrix button, .tommy-matrix .the-matrix .btn            { background: $zeb-color-primary; box-shadow: none; border: none; color: #fff;}
.tommy-matrix .the-matrix button:hover, .tommy-matrix .the-matrix .btn:hover{ background: $zeb-color-secondary; }
.tommy-matrix .the-matrix .popup .input-container_header::before            { content: ''; position: absolute; top: -10px; left: 15px; width: 0; height: 0; border-left: 10px solid transparent; border-right: 10px solid transparent; border-bottom: 10px solid $zeb-color-secondary; }
.tommy-matrix .popup .close i.fa                                            { font-size: 20px; }
