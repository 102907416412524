/* ==========================================================================
   Typography
   ========================================================================== */

/* Menu
   ========================================================================== */
.tt-menu-toggler {
  font-size: 0.875rem;
  color: $white;
  font-weight: $font-weight-bold;
}
.tt-menu-toggler--hover {}

.tt-menu-main {
  font-weight: $font-weight-bold;
  color: theme-color("secondary");
  text-decoration: $link-decoration;
  transition: $transition-base;
}
.tt-menu-main--hover {
  color: theme-color("primary");
}
.tt-menu-main--active {
  color: theme-color("secondary");
}
.tt-menu-main--last {}

.tt-menu-sub {
  color: theme-color("secondary");
  font-size: 0.8rem;
  text-decoration: $link-decoration;
  transition: $transition-base;
  font-weight: $font-weight-normal;
}
.tt-menu-sub--hover {
  color: theme-color("secondary");
}
.tt-menu-sub--active {
  color: theme-color("secondary");
}
.tt-menu-sub--last {}


/* Content
   ========================================================================== */
.tt-content-h1 {}
.tt-content-h2 {
  color: $primary;
  font-weight: $font-weight-base;
  @extend .mb-4;
}
.tt-content-h3 {}
.tt-content-h4 {}
.tt-content-h5 {}
.tt-content-h6 {}

.tt-content-text {}
.tt-content-text--white {
  //@extend .tt-content-text;
}

.tt-content-h-special {
        
  position: relative;
  color: $secondary;
  font-weight: $font-weight-base;
  @extend .mb-5;
  @extend .mx-0;
  @extend .mx-md-5;
  @extend .text-center;
  @extend .d-flex;
  @extend .justify-content-between;
  z-index: 1;
  overflow: hidden;
  flex-wrap: nowrap;
  font-size: $h2-font-size;

  &::before,
  &::after {
    content: '';
    background: $white;
    height: 30px;
    top: 0;
    overflow: hidden;
    flex: 1;
    background: transparent url('/images/wave-blue.png') 0 50% repeat-x;
  }
  &::before { margin-right: 60px; }
  &::after { margin-left: 60px; }

  @include media-breakpoint-down(md) {

    &::before,
    &::after {
      max-width: 100px;
    }
    &::before { margin-right: 30px; }
    &::after { margin-left: 30px; }

  }

}

/* Banner
   ========================================================================== */
.tt-banner-title {}
.tt-banner-subtitle {}
.tt-banner-text {}


/* Card
   ========================================================================== */
.tt-card-title {}
.tt-card-title--white {
  //@extend .tt-card-title;
  color: $white;
}

.tt-card-subtitle {}
.tt-card-subtitle--white {
  //@extend .tt-card-subtitle;
  color: $white;
}

.tt-card-text {}
.tt-card-text--white {
  //@extend .tt-card-text;
  color: $white;
}