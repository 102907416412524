.default {
	&.home {

		.section_one { // CONTENT 2 COLS
			@extend .px-3;
			@extend .px-sm-5;

			@extend .section-ypadding;
			.container {
				.column {
					@extend .mb-5;
					@extend .mb-md-0;
				}
			}
		}

		.section_two { // GRID ACCO
			@extend .px-3;
			@extend .px-sm-5;

			@extend .pt-5;
			@extend .pb-5;
			position: relative;

			background: transparent linear-gradient(360deg, $white 0%, #E5E5E5 100%) 0% 0% no-repeat;

			&::before {
				content: '';
				background: transparent url('/images/wave-grey.png') 0 0 repeat-x;
				height: 80px;
				position: absolute;
				top: -10px;
				left: 0;
				right: 0;
			}

			h2 {
				@extend .tt-content-h-special;
			}


		
			.page_block {
				&.grid {

					@extend .mb-3;
					
					.item {
						margin-bottom: 30px!important;
			
						@include media-breakpoint-up(sm) {
							@include make-col(12);
						}
			
						@include media-breakpoint-up(md) {
							@include make-col(6);
						}
			
						@include media-breakpoint-up(lg) {
							@include make-col(6);
						}
			
						@include media-breakpoint-up(xl) {
							@include make-col(6);
						}

					}
				}
			}
		}
		

		.section_three { // GRID ACCO
			@extend .px-3;
			@extend .px-sm-5;

			@extend .pt-3;
			@extend .pb-3;
			@extend .pb-sm-5;
			
			h2 {
				@extend .tt-content-h-special;
			}

		
			.page_block {
				&.grid {

					.item {
						margin-bottom: 30px!important;
			
						@include media-breakpoint-up(sm) {
							@include make-col(12);
						}
			
						@include media-breakpoint-up(md) {
							@include make-col(6);
						}
			
						@include media-breakpoint-up(lg) {
							@include make-col(4);
						}
			
						@include media-breakpoint-up(xl) {
							@include make-col(4);
						}
						
					}
				}
			}
		}

		.section_four {
			.container-fluid {
				@extend .p-0;

				
			}
		}

		.section_five {
			@extend .px-3;
			@extend .px-sm-5;
		}

		.section_six { 
			
		}
	}
}
