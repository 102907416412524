/* ==========================================================================
   Cards
   ========================================================================== */

.card {
  @extend .h-100;
  @extend .border-0;
  @extend .rounded-0;

  box-shadow: 0px 2px 10px rgba($dark, 0.4);

  /* Card - Image */
  .card-image {
    .card-image-link {
      @extend .d-block;
      .card-img-top {
        @extend .rounded-0;
      }
      .card-caption {
        .card-label {
          position: absolute;
          @extend .p-1;
          @extend .shadow;
          
          background-color: $theme-color-secondary;
          color: $white;
        }
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }

  /* Card - Body */
  .card-body {
    .card-caption {
      @extend .d-flex;
      @extend .flex-column;
      @extend .h-100;
      color: $secondary;
      .card-text ul {
        @extend .p-0;
        list-style: none;
      }
    }

    .card-title-link {
      @extend .d-block;
      &:hover {
        text-decoration: none;
        color: $secondary;
      }
    }

    .card-title {
      @extend .mb-1;
      @extend .font-weight-bold;
      &::after {
        margin-left: 10px;
        font-family: "Font Awesome 5 Pro";
        content: "\f061";
        font-weight: 400;
      }
    }

    .card-subtitle {
      @extend .my-2;

      font-size: $font-size-base;
      font-weight: $font-weight-normal;
      min-height: 30px;
    }

    .card-btn {
      @extend .align-self-start;
      @extend .mt-auto;
      @extend .btn-link;

      color: $secondary;
      text-decoration: underline;
      font-weight: $font-weight-base;
    }
  }

  // HIGHLIGHT - VERKOOP
  .highlight &,
  .assortiment_overview & {
    box-shadow: none;
    .card-body {
      @extend .px-0;
      .card-caption {
        color: $body-color;
        .card-text {
          border-left: 3px solid $primary;
          @extend .pl-3;
          @extend .my-2;
          font-weight: $font-weight-normal;
          p {
            @extend .my-0;
          }
          .price {
            font-weight: 700;
            font-size: $font-size-base * 1.15;
          }
        }
      }

      .card-subtitle,
      .card-btn {
        display: none;
      }
    }
  }

  /* Card - Img-overlay */
  .card-img-overlay {
    background-color: rgba($black, 0.8);

    .card-caption {
      @extend .text-white;
    }

    .card-title {
      @extend .mb-1;

      font-size: $font-size-base * 1.375;
      @extend .font-weight-bold;
    }

    .card-subtitle {
      @extend .mb-1;
      
      font-size: $font-size-base * 1.125;
    }

    .card-btn {
      @extend .btn;
      @extend .btn-outline-primary;
    }
  }

/* Card - Wide
   ========================================================================== */
  &.wide {
    @extend .d-flex;
    @extend .flex-row;
    @extend .flex-wrap;

    /* Card - Image */
    .card-image {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(sm) {
        @include make-col(4);
      }
      
      @extend .p-0;
    }

    /* Card - Body */
    .card-body {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(sm) {
        @include make-col(8);
      }

      .card-caption {
        .card-btn {
          @extend .mr-auto; 
        }
      }
    }
  }
}