.default {
	&.accommodation_overview,
	&.location_accommodation_overview,
	&.accommodation_category_overview,
	&.assortiment_category_overview {
		.section_one {
			@extend .px-3;
			@extend .py-4;

			@extend .px-sm-5;
			@extend .py-sm-5;

			.item {
				@extend .mb-5;
			}

			h1 {
				@extend .tt-content-h-special;
			}

		}
	}

	&.accommodation_arrangement_overview {
		.section_one {
			@extend .px-3;
			@extend .py-3;

			@extend .px-sm-5;
			@extend .py-sm-5;
		}
	}

	&.accommodation_search_book {
		// test
		font-size: 14px!important;

		.section_one {
			@extend .px-3;
			@extend .py-4;

			@extend .px-sm-5;
			@extend .py-sm-5;

			.tommy-zeb-wrapper {
				// reset tommy values
				@extend .d-flex;
				@extend .flex-column;
				float: none;
			}

			h1 {
				@extend .tt-content-h-special;
			}

			// container van maken
			.container-fluid {
				@extend .container;
			}

		}

	}

	&.accommodation_book {
		.section_one {
			@extend .px-3;
			@extend .py-4;

			@extend .px-sm-5;
			@extend .py-sm-5;

			h1 {
				@extend .tt-content-h-special;
			}

			// container van maken
			.container-fluid {
				@extend .container;
			}
		}

	}

	&.accommodation_detail {
		#js-collapse-accommodation-characteristics { cursor: pointer; }

		ul.list {
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			align-items: flex-start;
		}

		ul.list > .list-item {
			width: 100%;
			margin-bottom: 20px;

			@include media-breakpoint-up(md) {
				width: 50%;
			}
			@include media-breakpoint-up(lg) {
				width: 33%;
			}
		}
	}

	&.accommodation_detail,
	&.accommodation_category_detail,
	&.assortiment_category_detail {
		.section_one {
			@extend .px-3;
			@extend .py-4;

			@extend .px-sm-5;
			@extend .py-sm-5;
		}

		.accommodation_detail,
		.row {
			@extend .align-items-start;
			@include make-row();
			@extend .pb-5;

			.info,
			.col-12:nth-of-type(1) {
				@include make-col-ready();
				@include make-col(12);

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				@extend .mb-4;
				@extend .pr-lg-5;

				h1 {
					@extend .tt-content-h2;
				}
				.persons {
					color: $secondary;
				}
			}

			.content,
			.col-12:nth-of-type(2) {
				@include make-col-ready();
				@include make-col(12);

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				@extend .mb-4;
				@extend .pl-lg-5;

				@extend .d-flex;
				@extend .justify-content-end;
				@extend .flex-column;
				@extend .align-items-end;

				.btn-back {
					@extend .btn;
				}
			}

			.tommy,
			.accommodation-characteristics-list {
				@include make-col-ready();
				@include make-col(12);
				@extend .mx-auto;
				@extend .mt-5;
				@include media-breakpoint-up(xl) {
					@include make-col(9);
				}
			}
		}
	}
}


