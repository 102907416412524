.gallery {
  .gallery-holder {
    @include make-row();

    .highlight {
      @include make-col-ready();
      @include make-col(12);

      transition: $transition-base;

      img {
        width: 100%;
      }

      &:hover {
        transform: scale(1.025);
      }
    }

    .item {
      @include make-col-ready();
      @include make-col(12);

      @extend .mt-4;

      &.extra-thumbs {
        a {
          display: block;
          position: relative;
          .remaining {
            display: none;
            position: absolute;
            width: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            z-index: 99;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(0, 0, 0, 0.62);
            color: #fff;
            font-size: 50px;
            @include media-breakpoint-down(lg) {
              font-size: 30px;
            }
            @include media-breakpoint-down(md) {
              font-size: 50px;
            }
            &::before {
              content: '+';
            }
          }
        }
        @include media-breakpoint-up(md) {
          &.first,
          &.before-last {
            .remaining {
              display: none!important;
            }
          }
          &.last {
            .remaining {
              display: flex!important;
            }
          }
        }
        
        @include media-breakpoint-down(md) {
          &.before-last {
            .remaining {
              display: flex!important;
            }
          }
          &.first {
            .remaining {
              display: none!important;
            }
          }
          &.last {
            display: none!important;
          }
        }
      }

      &:hover {
        transform: scale(1.025);
      }

      @include media-breakpoint-up(md) {
        @include make-col(4);
      }
    }
  }
}