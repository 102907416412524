.page_block {
  &.contentblock {

    
  }
}

.container-two-columns,
.container-three-columns {
  .container-holder {
    h2 {
      @extend .tt-content-h2;
    }
  }
}
.main {
  .container-default {
    .container-holder {
      
      > a {
        @extend .btn;
        @extend .mx-auto;
        @extend .mt-0;
        @extend .mb-5;
        @extend .d-block;
        max-width: 250px;

      }
    }
  }
}

// mini sab blocks
.page_block {
  .mini-sab & {
    &.contentblock {
      &.grid {

        background: $white;
        margin: 40px -40px -25px;
        @extend .py-3;
        padding-left: 35px;
        padding-right: 35px;

        @include media-breakpoint-down(md) {
          margin: 40px -55px -40px;
          padding-top: 30px!important;
        }

        .item {

          @extend .mb-0;
          @extend .px-1;

          @include media-breakpoint-down(md) {
            margin-bottom: 15px!important;
          }

          @include media-breakpoint-up(sm) {
            @include make-col(4);
          }
    
          @include media-breakpoint-up(md) {
            @include make-col(4);
          }
  
          @include media-breakpoint-up(lg) {
            @include make-col(4);
          }
  
          @include media-breakpoint-up(xl) {
            @include make-col(4);
          }

          .card {

            &.icon {

              position: relative;
              box-shadow: none;
              border-radius: 3px!important;
              border: 1px solid $primary!important;

              .card-text,
              .card-buttons {
                @extend .d-none;
              }

              .card-image {
                position: absolute;
                right: 10px;
                top: 5px;
                font-size: 30px;
                color: $secondary;
                a {
                  color: $secondary;
                }
              }
              .card-body {
                padding: 5px 10px;

                .card-title {
                  font-size: 1.8rem;
                  color: $primary;
                  &::after {
                    @extend .d-none;
                  }
                }

                .card-subtitle {
                  min-height: inherit;
                  @extend .my-0;
                }
              }
            }

          }

        }
      }
    }
  }
}