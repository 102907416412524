/* ==========================================================================
   Base
   ========================================================================== */

/* Body
   ========================================================================== */

  
body.default {
  @include media-breakpoint-up(xl) {
    padding-top: 110px;
    
    transition: $transition-base;
  }

  &.sticky {
    @include media-breakpoint-up(xl) {
      padding-top: 112px;
    }
  }
}


/* Main
   ========================================================================== */
.main {
  /* Headlines */
  h1 {
    //@extend .tt-content-h1;
  }
  h2 {
    //@extend .tt-content-h2;
  }
  h3 {
    //@extend .tt-content-h3;
  }
  h4 {
    //@extend .tt-content-h4;
  }
  h5 {
    //@extend .tt-content-h5;
  }
  h6 {
    //@extend .tt-content-h6;
  }

  /* Paragraph */
  p {
    //@extend .tt-content-text;
  }

  /* List */
  ul,
  ol {
    li {
      a {}
    }
  }
}

/* Links
   ========================================================================== */

a {
  transition: $transition-base;
}